.custom-control {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.custom-control-input:not(:disabled):hover ~ .custom-control-label::before {
  border-width: 3px;
}

$form-font-size: $font-size-base * 1.125;
$form-control-height: 3.125rem;

legend {
  font-size: 1rem;
}

.required {
  label,
  legend {
    &:after {
      color: $form-feedback-invalid-color;
      content: " *";
    }
  }
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    &:after {
      content: "";
    }
  }
}

legend {
  &.required {
    &:after {
      color: $form-feedback-invalid-color;
      content: " *";
    }
  }
}

.form-control[readonly],
.form-control[readonly] + .input-group-append > .input-group-text {
  border-style: dotted;
}

.custom-control-label {
  cursor: pointer;
  color: $blaa;
}

.custom-control-input {
  &:disabled {
    ~ .custom-control-label {
      cursor: not-allowed;
    }
  }
}

.custom-checkbox {
  .custom-control-input {
    &--inverted {
      &:checked ~ .custom-control-label {
        &::before {
          background-color: white;
        }

        &::after {
          background-image: $custom-checkbox-indicator-icon-inverted-checked;
        }
      }

      &:indeterminate {
        ~ .custom-control-label {
          &::before {
            border-color: white;
            background-color: white;
          }
          &::after {
            background-image: $custom-checkbox-indicator-icon-inverted-indeterminate;
          }
        }
      }

      ~ .custom-control-label::before {
        border-color: white;
      }
    }
  }
}

.invalid-feedback {
  font-size: 100%;
}

[data-watch-changes] {
  ~ .changed-feedback {
    display: none;
    width: 100%;
    color: $motorst;
  }

  &.value-changed ~ .changed-feedback {
    display: block;
  }
}

.input-group-text {
  &.align-units {
    min-width: 5rem;
  }
}

.input-group-prepend .btn {
  min-width: 3.125rem;
}

.input-group-sum {
  border-bottom: double 4px black;
  .form-control,
  .input-group-text {
    border-color: transparent;
  }
}

.input-group-borderless {
  .form-control,
  .input-group-text {
    border-color: transparent;
  }
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows[type="number"] {
  -moz-appearance: textfield;
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    box-shadow: $input-focus-box-shadow;
  }

  .was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }
}
