$sort-size: 0.5rem;

.dropdown-toggle:focus {
	box-shadow: $custom-control-indicator-focus-box-shadow;
}

.table {
	height: 1px;

	caption {
		caption-side: top;
		color: inherit;
		font-weight: bold;
		margin: 0;

		&.table-status {
			padding: 0;
			background-color: $blaa;
			color: white;

			> div {
				display: flex;
				position: relative;
				padding: 1rem $table-cell-padding;
				align-items: center;

				&::after {
					content: "";
					@include trekant(0.75rem);
					position: absolute;
					bottom: -10px;
					left: 30px;
					border-top-color: $blaa;
				}

				:last-child {
					margin-left: auto;
				}
			}
		}
	}

	tr.deleting {
		td {
			opacity: 0.5;
		}
	}

	thead {
		$header-border: solid 2px $blaa;

		tr:first-child {
			th,
			td {
				border-top: $header-border;
			}
		}
		tr:last-child {
			th,
			td {
				border-bottom: $header-border;
			}
		}
		th {
			vertical-align: top;
			font-weight: normal;

			&.sortable {
				padding: 0;

				> .btn {
					padding: calc(#{$table-cell-padding} - 2px);
					padding-right: 36px;
					text-align: left;
					width: calc(100% - 4px);
					position: relative;
					margin: 0 2px;
				}

				&.sorted {
					> .btn {
						font-weight: bold;

						&::after {
							content: "";
							position: absolute;
							right: $table-cell-padding;
							top: calc(50% - calc(#{$sort-size} / 2));
							@include trekant($sort-size);
						}
					}
					&.ascending {
						> .btn {
							&::after {
								transform: rotate(0.5turn);
							}
						}
					}
				}
			}
		}
	}

	th,
	td {
		transition: opacity ease-in 0.5s;

		&.checkbox-cell {
			width: 24px;

			.custom-control {
				padding-top: 0;
				padding-bottom: 0;
				padding-left: 1.5rem;

				&-label {
					&::before {
						left: -1.5rem;
					}
					&::after {
						left: -1.5rem;
					}
				}
			}
		}
	}

	tr.selected {
		background-color: $baggrund-graa;
	}

	tr:hover,
	tr.selected {
		td {
			&.actions-cell {
				.action {
					opacity: 1;
				}
			}
			.dropdown-menu {
				display: block;
				background-color: $baggrund-graa;
			}
		}
	}
}

h3.popover-header {
	margin-top: 0;
}
