@import "../2-tools/tools.spacing-mixins.scss";

$spacings: (
	none: 0,
	xxs: var(--spacing--2xs),
	xs: var(--spacing--xs),
	sm: var(--spacing--sm),
	md: var(--spacing--md),
	lg: var(--spacing--lg),
	xl: var(--spacing--xl),
);

.u-padding--sides {
	@include side-padding;
}

@each $name, $value in $spacings {
	.u-padding--#{$name} {
		padding: $value;
	}

	.u-bottom-margin--#{$name} {
		margin-bottom: $value;
	}

	.u-bottom-margin-important--#{$name} {
		margin-bottom: $value !important;
	}

	.u-bottom-padding--#{$name} {
		padding-bottom: $value;
	}

	.u-top-margin--#{$name} {
		margin-top: $value;
	}

	.u-top-margin-important--#{$name} {
		margin-top: $value !important;
	}

	.u-top-padding--#{$name} {
		padding-top: $value;
	}

	.u-left-padding--#{$name} {
		padding-left: $value;
	}

	.u-left-margin--#{$name} {
		margin-left: $value;
	}

	.u-right-padding--#{$name} {
		padding-right: $value;
	}

	.u-right-margin--#{$name} {
		margin-right: $value;
	}
	.u-child-margin--#{$name} {
		> * {
			margin: 0;
		}
	}
}
