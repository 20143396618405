.nav-item {
	position: relative;

	&:not(:last-child) {
		border-right: solid 1px transparent;
	}
	.nav-link {
		&.active:before {
			font-family: $icomoon-font-family;
			content: $ikon-ok;
			font-size: 0.6em;
			margin-right: 0.5rem;
		}

		background-color: $blaa;
		color: white;
		transition: background-color 0.3s;
		&:hover,
		&:focus {
			&:not(.active) {
				background-color: $blaa-60;
				color: white;
				border-color: $blaa-60;
				border-radius: 0;
			}
		}
	}
}

