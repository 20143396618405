/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

html {
	scroll-behavior: smooth;
	// font-size: 10px;
}
