@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Regular.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Regular.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Regular.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Italic.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Italic.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Italic.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Italic.woff") format("woff");
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Medium.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Medium.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Medium.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Demibold.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Demibold.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Demibold.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Demibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Bold.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Bold.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Bold.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "Academy Sans";
	src: url("../../public/fonts/AcademySans/AcademySans-Black.eot");
	src: url("../../public/fonts/AcademySans/AcademySans-Black.eot?#iefix") format("embedded-opentype"),
		url("../../public/fonts/AcademySans/AcademySans-Black.woff2") format("woff2"),
		url("../../public/fonts/AcademySans/AcademySans-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-Light.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-Light.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-LightItalic.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-LightItalic.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-LightItalic.woff") format("woff");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-Regular.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-Regular.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-Italic.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-Italic.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-Italic.woff") format("woff");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-DemiBold.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-DemiBold.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-DemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-Bold.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-Bold.woff2") format("woff2"), url("../../public/fonts/Republic/Republic-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Republic";
	src: url("../../public/fonts/Republic/Republic-Heavy.eot") format("embedded-opentype");
	src: url("../../public/fonts/Republic/Republic-Heavy.woff2") format("woff2"),
		url("../../public/fonts/Republic/Republic-Heavy.woff") format("woff");
	font-weight: 800;
	font-style: normal;
}

p {
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
	max-width: 47rem;
}

a {
	&:focus {
		color: $blaa-60;
		box-shadow: $alt-btn-focus;
	}
}

.hyphens {
	-ms-hyphens: auto !important;
	-webkit-hyphens: auto !important;
	hyphens: auto !important;
}

code {
	hyphens: none;
}

.ikon.ikon--large {
	font-size: 1.5rem;
}
