.help-icon {
	position: relative;
	display: inline-block;
	text-align: center;
	border: none;
	background-color: var(--color-white);
	margin: 0 0 0.3rem 0.3rem;
	padding: 0;
	width: 1.5em;
	height: 1.5em;
	font-weight: bold;
	border-radius: 50%;

	.sop-app & {
		margin: 0 0 0 0.3rem;
	}

	&:focus {
		box-shadow: none;
	}
	&,
	&:hover {
		text-decoration: none;
	}

	&::before {
		content: "";
		border: solid 1px $blaa;
		border-radius: 50%;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:hover,
	.hover,
	&:focus,
	&.focus {
		outline: 0;

		&::before {
			box-shadow: $custom-control-indicator-focus-box-shadow;
		}
	}
}
