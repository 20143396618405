@import "../2-tools/tools.display-mixins.scss";
@import "../2-tools/tools.typography-mixins.scss";

.collapse {
	transition: height 300ms ease-in-out;
	overflow: hidden;
}
.collapse:not(.show) {
	@include visually-hidden;
}

.collapser {
	$triangle-size: 8px;
	$triangle-size-small: 6px;

	&__header {
		width: 100%;
		padding: 0;
		display: flex;
		align-items: center;
		color: $blaa;
		text-align: left;
		background: transparent;
		border: none;
		hyphens: auto;

		&:focus {
			outline: 0;
			box-shadow: $btn-focus-box-shadow;
		}

		> img {
			margin-right: 1rem;
		}

		&[aria-expanded="true"] {
			border-bottom: 1px solid transparent;
			&::before {
				transform: translate(-25%, -25%) rotate(225deg);
			}
		}

		&[aria-expanded="false"],
		&:hover:not(:focus) {
			border-bottom: 1px solid $blaa;
		}

		&::before {
			content: "";
			display: inline-block;
			flex-shrink: 0;
			width: $triangle-size;
			height: $triangle-size;
			margin-right: 1rem;
			margin-left: 1px;
			border-style: solid;
			border-width: 0 2px 2px 0;
			transform-origin: 75% 75%;
			transition: transform ease-in-out 0.3s;
			transform: translate(-25%, -25%) rotate(45deg);
		}

		&___small {
			&::before {
				width: $triangle-size-small;
				height: $triangle-size-small;
			}
		}

		&___noTriangle {
			&::before,
			&[aria-expanded="false"],
			&[aria-expanded="true"],
			&:hover:not(:focus) {
				border: none;
				box-shadow: none;
			}
		}

		&___noTriangleContent {
			&::before {
				display: none;
				margin: 0;
				width: 0;
				margin: 0;
			}
		}

		&___noBorder {
			&[aria-expanded="false"],
			&[aria-expanded="true"],
			&:hover:not(:focus) {
				border: none;
			}
		}
	}

	&__body {
		border-bottom: solid 1px $blaa;
		padding-bottom: 0.75rem;
	}
}
