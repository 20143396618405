.u-align-textRight {
	text-align: right;
}
.u-margin-left-auto {
	margin-left: auto;
}

.u-display-flex {
	display: flex;
}
.u-justify-content-end {
	justify-content: end;
}
