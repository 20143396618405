.modal {
  &-header {
    border-bottom: none;

    .close {
      width: 2.5rem;
      height: 2.5rem;
      background-color: $blaa;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' width='15.9px' height='16px' viewBox='12 12 15.9 16' %3E%3Cpolygon fill='%23FFFFFF' points='28,13.2 26.8,12 20,18.8 13.2,12 12,13.2 18.8,20 12,26.8 13.2,28 20,21.2 26.8,28 28,26.8 21.2,20 ' /%3E%3C/svg%3E");
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 1;
    }
  }

  &-title {
    margin: 0;
  }

  &-footer {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

button.close {
  &:focus {
    box-shadow: $btn-focus-box-shadow;
    outline: solid 1px white;
  }
}
