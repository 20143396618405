.related-link {
	position: relative;
	margin-bottom: 0.7rem;
	padding: 0 1rem 0 2rem;
	text-decoration: none;
	display: inline-block;

	&:hover,
	&:focus {
		color: var(--color-white);
		text-decoration: none;
		outline: none;
		background: var(--color-primary--60);

		&::before,
		&::before {
			left: 0.7rem;
		}
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 8px;
		height: 8px;
		border: solid currentColor;
		border-width: 2px 2px 0 0;
		transform: translateY(-50%) rotate(45deg);
		transition: all 0.3s;
	}
	&--light {
		color: var(--color-white);
	}
}
