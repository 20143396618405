@import "../2-tools/tools.media-query.scss";

.u-aside {
	display: grid;
	@include mq("lg") {
		grid-template-columns: minmax(0, 72fr) 28fr;
		column-gap: var(--spacing--lg);
	}
	@include mq("lg", max) {
		grid-template-columns: 100%;
	}
}
