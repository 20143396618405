/* ------------------------------------ *\
		tools.action
\* ------------------------------------ */

@import "../../styles/2-tools/tools.behavior.scss";

// Action
@mixin action {
	padding: 0;
	background: transparent;
	border-color: transparent;
	font-weight: var(--font-weight--semibold);
	cursor: pointer;
}

// Input
@mixin input {
	@include paragraph;
	outline: none;
	padding: var(--spacing--sm) var(--spacing--base);

	&.hasError {
		border-color: var(--color-error);
		color: var(--color-error);
	}

	&::placeholder {
		color: currentColor;
	}

	// Used for text, email, password, number etc.
	&___string {
		width: 100%;
		border: var(--border--sm) solid transparent;
		background: var(--color-primary--40);
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}

	&___string:focus,
	&___active {
		// outline-color: transparent;
		border-color: currentColor;
	}

	// Used for checkbox and radio
	&___choice {
		height: 1.5em;
		width: 1.5em;
	}

	// Used for textarea
	&___textarea {
		width: 100%;
		border: var(--border--sm) solid currentColor;
		-webkit-appearance: none;

		&.hasError {
			border-color: var(--color-error);
		}
	}
}
