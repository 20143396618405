.upload-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;

	&:focus {
		+ label {
		box-shadow: $alt-btn-focus;
		.rounded-icon,
		.ikon-rundt {
			background-color: $blaa-60;
		}
		}
	}

	+ label {
		cursor: pointer;
		display: inline-block;
		&:hover {
		text-decoration: underline;
		}
	}
}
