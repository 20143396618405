$alt-btn-size: 2rem;
$alt-btn-spacing: 0.5rem;

.btn {
  min-width: 5rem;
  text-decoration: none;

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    @include hover-focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-sm {
    min-width: auto;
    line-height: 1;
    padding: 0.5rem;
  }
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding: 0;
}

.form-control:disabled,
.form-control[readonly] {
  + .input-group-append > {
    span {
      background-color: $input-disabled-bg;
    }
  }
}

.btn-primary {
  @include hover-focus {
    outline: solid 1px white;
    box-shadow: $btn-focus-box-shadow;
    background-color: $blaa-80;
  }
}

.alt-btn {
  display: inline-flex;
  color: $blaa-80;
  &.alt-btn--inverted {
    color: white;
  }
  align-items: center;
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;

  &:not(:disabled) {
    @include hover-focus {
      text-decoration: underline;
    }
  }

  &:focus {
    box-shadow: $alt-btn-focus;
    outline: none;
  }
  + .alt-btn {
    margin-left: 1.25em;
  }

  &::before,
  &::after {
    font-family: $icomoon-font-family;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $alt-btn-size;
    height: $alt-btn-size;
    background-color: $blaa;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &.alt-btn--green {
    color: $motorst;

    &::before,
    &::after {
      background-color: $motorst;
    }
  }
  &.alt-btn--inverted {
    &::before,
    &::after {
      color: $blaa;
      background-color: white;

      &:hover {
        color: white;
      }
    }

    @include hover-focus {
      &::before {
        color: $blaa;
        background-color: white;
      }
    }
  }

  &:not(:empty)::before {
    margin-right: $alt-btn-spacing;
  }

  @each $name, $ikon in $ikoner {
    &.alt-btn-#{$name} {
      &::before {
        content: $ikon;
      }

      &.alt-btn--flipped {
        &::before {
          content: none;
        }

        &::after {
          content: $ikon;
        }
      }
    }
  }

  &.alt-btn-download:before {
    content: $ikon-upload;
    transform: rotate(0.5turn);

    &.alt-btn--flipped {
      &::before {
        content: none;
      }

      &::after {
        content: $ikon-upload;
        transform: rotate(0.5turn);
      }
    }
  }

  &.alt-btn--flipped {
    &::after {
      margin-left: $alt-btn-spacing;
    }
    @include hover-focus {
      &::after {
        background-color: $blaa-60;
        color: white;
      }
    }
  }

  @include hover-focus {
    &:not(:disabled) {
      color: $blaa-60;

      &::before {
        background-color: $blaa-60;
        color: white;
      }
    }

    &.alt-btn--green {
      &:not(:disabled) {
        &::before {
          background-color: $motorst-60;
        }
      }
    }
  }
}

.alt-btn.alt-btn-spinner {
  position: relative;

  &::before {
    content: '\00a0';
  }
  &::after {
    content: '';
    position: absolute;
    left: 0px;
    top: calc(50% - 1rem);
    border-radius: 0;
    background-color: transparent;
    background-size: 19.2px 19.2px;
    background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%0D%0A%09xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2238px%22%20height%3D%2238px%22%20viewBox%3D%220%200%2038%2038%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20d%3D%22M19%2C3.1c8.8%2C0%2C15.9%2C7.1%2C15.9%2C15.9S27.8%2C34.9%2C19%2C34.9c-4.3%2C0-8.3-1.7-11.1-4.5l-2.1%2C2.2%0D%0A%09C9.2%2C35.9%2C13.8%2C38%2C19%2C38c10.5%2C0%2C19-8.5%2C19-19S29.5%2C0%2C19%2C0l0%2C0C13.8%2C0%2C9.2%2C2.1%2C5.7%2C5.4l2.2%2C2.2C10.8%2C4.8%2C14.7%2C3.1%2C19%2C3.1%22%2F%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M7.9%2C30.4c-3-2.9-4.8-6.9-4.8-11.4c0-4.5%2C1.8-8.5%2C4.8-11.4L5.7%2C5.4C2.2%2C8.9%2C0%2C13.7%2C0%2C19%0D%0A%09c0%2C5.3%2C2.2%2C10.2%2C5.7%2C13.6L7.9%2C30.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
    animation: rotating 1s linear 0s infinite;
    transition: opacity 0.3s ease-in-out;
  }
}

.btn.btn-profile {
  border: solid 1px $blaa;
//   @include media-breakpoint-up('sm') {
    // border-right: none;
    // border-top: none;
  // }
  display: inline-flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &:hover {
    color: white;
    background: $blaa-60;
    box-shadow: none;
    border-color: transparent;
  }

  &:focus {
    box-shadow: $btn-focus-box-shadow inset;
    border: none;
  }

  &:disabled,
  &.disabled {
    border-color: $graa;
    border-bottom: none;
    pointer-events: all;
  }
}
