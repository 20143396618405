.arrow-link {
	cursor: pointer;
	position: relative;
	padding: 0 var(--spacing--base) 0 var(--spacing--xl);
	text-decoration: none;
	display: inline-block;
	color: currentColor;
	outline: none;

	&:hover,
	&:focus {
		color: var(--color-white);
		text-decoration: none;
		background: var(--color-primary--60);

		&::before {
			left: 0.7rem;
		}
	}

	&::before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 11px;
		transform: translateY(-50%) rotate(45deg);
		width: 8px;
		height: 8px;
		border: solid currentColor;
		border-width: 2px 2px 0 0;
		transition: all 0.3s;
	}
}
