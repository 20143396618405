.CookieDeclaration {
	h2 {
		@include heading--md;
		margin-bottom: var(--spacing--md);
	}

	p {
		@include paragraph;

		margin-bottom: var(--spacing--md);

		&:last-of-type {
			margin-bottom: 0;
		}
	}
	table {
		border-collapse: collapse;
		width: 100%;
		border: var(--border--sm);
		border-color: var(--color-bg-subtle);
		margin: var(--spacing--lg) 0 0 0;
		thead {
			border-top: var(--border--md) solid var(--color-black);
			border-bottom: var(--border--md) solid var(--color-black);
		}
		td,
		th {
			padding: var(--spacing--lg);
		}
		caption {
			@include visually-hidden;
		}
		&:global(.compact) {
			td,
			th {
				padding: var(--spacing--sm);
			}
		}
		tbody {
			tr:hover,
			tr:focus {
				background-color: var(--color-bg-subtle);
			}
		}
	}
	ul,
	ol {
		margin: var(--spacing--base) 0;
		padding-left: var(--spacing--xl);
		line-height: var(--line-height--2xl);
		list-style-position: inside;
		ul,
		ol {
			margin: 0;
		}
	}
}
