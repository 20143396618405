/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

:root {
	// Font family
	--font-primary: "Academy Sans", sans-serif;

	--font-heading: var(--font-primary);

	// Font weights
	--font-weight--normal: 400;
	--font-weight--semibold: 600;
	--font-weight--bold: 700;
	--font-weight--black: 900;

	// Font sizes
	--font-size--2xs: 0.625rem; //10px
	--font-size--xs: 0.75rem; // 12px
	--font-size--sm: 0.875rem; //14px
	--font-size--md: 1rem; // 16px
	--font-size--lg: 1.25rem; // 20px
	--font-size--xl: 1.5rem; // 24px
	--font-size--2xl: 1.75rem; // 28px
	--font-size--3xl: 2.8125rem; // 45px

	--heading--line-height: 1.2;
	--teaser--line-height: 1.3;
}
