@import "../2-tools/tools.rem.scss";

/* ------------------------------------ *\
		settings.borders
\* ------------------------------------ */
$border-base: 0.0625rem; //1px

:root {
	--border--sm: #{$border-base};
	--border--md: calc(#{$border-base} * 2);
	--border--lg: calc(#{$border-base} * 3);
	--border--xl: calc(#{$border-base} * 4);
	--border--2xl: calc(#{$border-base} * 5);
	--border--3xl: calc(#{$border-base} * 6);
}
