.btn-actions-dots {
	background-color: transparent;
	padding: 0rem;
	background-image: url("../../icons/actiondots.svg?url");
	background-repeat: no-repeat;
	background-position: 3px -1px;
	height: 1.5rem;
	min-width: 1rem;
	border: none;

	&:focus {
		box-shadow: 0 0 0 3px var(--color-primary--60);
		outline: none;
	}
}
