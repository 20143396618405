.btn-table-cell {
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    background-color: $blaa-60;
    color: white;
  }

  &:focus {
    border: solid 1px white;
    box-shadow: 0 0 0 0.2rem #72728a;
  }
}