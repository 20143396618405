.theme-motorst {
	--color-theme-primary: #008139;
	--color-theme-primary--60: #66b388;
	--color-theme-primary--30: #b2d9c4;

	--color-theme-contrast: var(--color-white);

	--color-theme-button: var(--color-white);
	--color-theme-button-contrast: var(--color-primary);
}
