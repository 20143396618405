/* ------------------------------------ *\
		settings.widths
\* ------------------------------------ */

:root {
	--width--base: calc(0.625rem * 100); // 1000px

	--width-text-max: calc(var(--width--base) * 0.752);
	--width-text-max-sm: calc(var(--width-text-max) * 0.75);

	--width-input: calc(var(--width--base) * 0.2);
	--width-input--large: calc(var(--width--base) * 0.3);

	--width-sm: calc(var(--width--base) * 0.943);
	--width-md: calc(var(--width--base) * 1.4);
	--width-lg: calc(var(--width--base) * 1.6);
	--width-xl: calc(var(--width--base) * 2);
}
