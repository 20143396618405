.dropdown {
  &.action-dropdown {
    .dropdown-toggle {
      border: none;
    }
  }

  &-toggle {
    background-color: $blaa;
    color: white;
    border: solid 1px $blaa;
    padding: 0.75rem 2rem;
    transition: background-color 0.3s;

    &--light {
      background-color: white;
      color: $blaa;
    }

    &:hover {
      outline: solid 1px white;
      box-shadow: $btn-focus-box-shadow;
      background-color: $blaa-80;
    }

    &:focus {
      outline: solid 1px white;
      box-shadow: $btn-focus-box-shadow;
    }

    .light &,
    .h1 &,
    .h2 & {
      background: white;
      color: inherit;
      font-weight: inherit;
      border: solid 1px $blaa;

      &:hover,
      &:active {
        outline: none;
        box-shadow: none;
        background-color: $blaa-20;
      }
      &:focus {
        outline: none;
        box-shadow: $btn-focus-box-shadow;
      }
    }

    .h1 & {
      padding: 1rem 1.5rem;
    }

    .h2 & {
      padding: 0.8rem 1.1rem;
    }

    &::after {
      border-top-width: $caret-width * 1.5;
      transition: transform ease-in-out 0.2s;
    }

    &[aria-expanded='true']::after {
      transform: rotate(0.5turn);
    }
  }
  &-menu {
    .h1 &,
    .h2 & {
      margin-top: -1px;
      border: none;
      padding: 0;
      min-width: auto;
    }
  }
  &-item {
    cursor: pointer;
    transition: background-color 0.3s;
    .h1 &,
    .h2 & {
      $drop-item-border-style: 1px solid #14143c;
      border: $drop-item-border-style;
      border-bottom: none;

      &:last-child {
        border-bottom: $drop-item-border-style;
      }

      &:active,
      &.active {
        color: $blaa;
        background-color: $blaa-20;
      }

      @include hover-focus {
        outline: none;
        background-color: $blaa-20;
      }
    }

    .h1 & {
      padding: 1rem 1.5rem;
    }

    .h2 & {
      padding: 0.8rem 1.1rem;
    }
  }
}
