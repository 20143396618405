/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

:root {
	// Base colors
	--color-black: #000000;
	--color-white: #ffffff;

	// Color palette named by https://chir.ag/projects/name-that-color
	--color-haiti: #14143c;
	--color-mulled-wine: #434363;
	--color-storm-gray: #72728a;
	--color-santas-gray: #a1a1b1;
	--color-mischka: #d0d0d8;
	--color-curious-blue: #2e99d9;
	--color-silver-rust: #d1c5c3;
	--color-guardsman-red: #c80000;
	--color-mine-shaft: #2c2c2c;
	--color-concrete: #f2f2f2;
	--color-mercury: #e5e5e5;
	--color-catskill-white: #e3ebf2;
	--color-ebb: #e8e2e1;
	--color-fun-green: #008139;
	--color-surf: #b2d9c4;
	--color-mandys-pink: #f5b4b9;
	--color-lightning-yellow: #ffbb16;
	--color-monza: #df0515;
	--color-mint-tulip: #c0e0f4;
	--color-colonial-white: #ffebb9;

	// Primary colors
	--color-primary: var(--color-haiti);
	--color-primary--80: var(--color-mulled-wine);
	--color-primary--60: var(--color-storm-gray);
	--color-primary--40: var(--color-santas-gray);
	--color-primary--20: var(--color-mischka);
	--color-secondary: var(--color-curious-blue);
	--color-tertiary: var(--color-silver-rust);

	// Utilities colors
	--color-error: var(--color-guardsman-red);
	--color-dark: var(--color-mine-shaft);
	--color-light: var(--color-concrete);
	--color-success: var(--color-fun-green);
	--color-warning: var(--color-lightning-yellow);
	--color-danger: var(--color-monza);

	// Border colors
	--color-border--gray: var(--color-primary--40);
	--color-border--light: var(--color-light);

	// Shadow colors
	--shadow-color--milder: rgba(var(--color-dark), 0.1);
	--shadow-color--mild: rgba(var(--color-dark), 0.2);
	--shadow-color--base: rgba(var(--color-dark), 0.5);
	--shadow-color--danger: rgba(223, 5, 21, 0.25); // rgba(var(--color-danger), 0.25); is not working

	// Background colors
	--color-bg-subtle: var(--color-mercury);
	--color-bg--warm: var(--color-ebb);
	--color-bg--light-blue: var(--color-catskill-white);
	--color-bg--success: var(--color-surf);
	--color-bg--error: var(--color-mandys-pink);
	--color-bg--warning: var(--color-colonial-white);

	// Highlight colors
	--color-hightlight-blue: var(--color-mint-tulip);
}
