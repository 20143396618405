@import 'icon_font_variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?izqaga')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?izqaga')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?izqaga##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ikon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ikon-sprogvaelger {
	&:before {
		content: $ikon-sprogvaelger;
	}
}
.ikon-se {
  &:before {
    content: $ikon-se;
  }
}
.ikon-skift-kunde {
  &:before {
    content: $ikon-skift-kunde;
  }
}
.ikon-start-forfra {
  &:before {
    content: $ikon-start-forfra;
  }
}
.ikon-nulindberet {
  &:before {
    content: $ikon-nulindberet;
  }
}
.ikon-kopier {
  &:before {
    content: $ikon-kopier;
  }
}
.ikon-maalflag {
  &:before {
    content: $ikon-maalflag;
  }
}
.ikon-ok {
  &:before {
    content: $ikon-ok;
  }
}
.ikon-tilfoej {
  &:before {
    content: $ikon-tilfoej;
  }
}
.ikon-print {
  &:before {
    content: $ikon-print;
  }
}
.ikon-gem {
  &:before {
    content: $ikon-gem;
  }
}
.ikon-slet {
  &:before {
    content: $ikon-slet;
  }
}
.ikon-rediger {
  &:before {
    content: $ikon-rediger;
  }
}
.ikon-skatten-2019 {
  &:before {
    content: $ikon-skatten-2019;
  }
}
.ikon-godt-fra-start {
  &:before {
    content: $ikon-godt-fra-start;
  }
}
.ikon-skatten-2018 {
  &:before {
    content: $ikon-skatten-2018;
  }
}
.ikon-gaeld {
  &:before {
    content: $ikon-gaeld;
  }
}
.ikon-afloeb-draen {
  &:before {
    content: $ikon-afloeb-draen;
  }
}
.ikon-afskrivning {
  &:before {
    content: $ikon-afskrivning;
  }
}
.ikon-asbest-pcb-bly {
  &:before {
    content: $ikon-asbest-pcb-bly;
  }
}
.ikon-beregn {
  &:before {
    content: $ikon-beregn;
  }
}
.ikon-boede {
  &:before {
    content: $ikon-boede;
  }
}
.ikon-boernepasning {
  &:before {
    content: $ikon-boernepasning;
  }
}
.ikon-braendeovne {
  &:before {
    content: $ikon-braendeovne;
  }
}
.ikon-doedsfald {
  &:before {
    content: $ikon-doedsfald;
  }
}
.ikon-ejendomsvurdering {
  &:before {
    content: $ikon-ejendomsvurdering;
  }
}
.ikon-excel {
  &:before {
    content: $ikon-excel;
  }
}
.ikon-fjernvarmeunits {
  &:before {
    content: $ikon-fjernvarmeunits;
  }
}
.ikon-flytning {
  &:before {
    content: $ikon-flytning;
  }
}
.ikon-formue {
  &:before {
    content: $ikon-formue;
  }
}
.ikon-glasdoere {
  &:before {
    content: $ikon-glasdoere;
  }
}
.ikon-havearbejde {
  &:before {
    content: $ikon-havearbejde;
  }
}
.ikon-henvendelser {
  &:before {
    content: $ikon-henvendelser;
  }
}
.ikon-hjaelp {
  &:before {
    content: $ikon-hjaelp;
  }
}
.ikon-indenfor-eu {
  &:before {
    content: $ikon-indenfor-eu;
  }
}
.ikon-indstillinger {
  &:before {
    content: $ikon-indstillinger;
  }
}
.ikon-information {
  &:before {
    content: $ikon-information;
  }
}
.ikon-installation-af-varmepumper {
  &:before {
    content: $ikon-installation-af-varmepumper;
  }
}
.ikon-integrer {
  &:before {
    content: $ikon-integrer;
  }
}
.ikon-intelligent-styring {
  &:before {
    content: $ikon-intelligent-styring;
  }
}
.ikon-isolering-af-gulv {
  &:before {
    content: $ikon-isolering-af-gulv;
  }
}
.ikon-isolering-af-tag {
  &:before {
    content: $ikon-isolering-af-tag;
  }
}
.ikon-isolering-af-ydervaegge {
  &:before {
    content: $ikon-isolering-af-ydervaegge;
  }
}
.ikon-job {
  &:before {
    content: $ikon-job;
  }
}
.ikon-kalender {
  &:before {
    content: $ikon-kalender;
  }
}
.ikon-kontakt {
  &:before {
    content: $ikon-kontakt;
  }
}
.ikon-kontekstuel-lovgivning {
  &:before {
    content: $ikon-kontekstuel-lovgivning;
  }
}
.ikon-ladestik-til-elbiler {
  &:before {
    content: $ikon-ladestik-til-elbiler;
  }
}
.ikon-loenindkomst {
  &:before {
    content: $ikon-loenindkomst;
  }
}
.ikon-lyt {
  &:before {
    content: $ikon-lyt;
  }
}
.ikon-pdf {
  &:before {
    content: $ikon-pdf;
  }
}
.ikon-pension {
  &:before {
    content: $ikon-pension;
  }
}
.ikon-permeable-belaegninger {
  &:before {
    content: $ikon-permeable-belaegninger;
  }
}
.ikon-play {
  &:before {
    content: $ikon-play;
  }
}
.ikon-problem {
  &:before {
    content: $ikon-problem;
  }
}
.ikon-punktafgifter {
  &:before {
    content: $ikon-punktafgifter;
  }
}
.ikon-radonsikring {
  &:before {
    content: $ikon-radonsikring;
  }
}
.ikon-registreringsafgift {
  &:before {
    content: $ikon-registreringsafgift;
  }
}
.ikon-rengoering {
  &:before {
    content: $ikon-rengoering;
  }
}
.ikon-renteudgifter-oevrige {
  &:before {
    content: $ikon-renteudgifter-oevrige;
  }
}
.ikon-raadgivere-revisorer {
  &:before {
    content: $ikon-raadgivere-revisorer;
  }
}
.ikon-raadgivning {
  &:before {
    content: $ikon-raadgivning;
  }
}
.ikon-segment {
  &:before {
    content: $ikon-segment;
  }
}
.ikon-send {
  &:before {
    content: $ikon-send;
  }
}
.ikon-servicedesk {
  &:before {
    content: $ikon-servicedesk;
  }
}
.ikon-skattekort {
  &:before {
    content: $ikon-skattekort;
  }
}
.ikon-skorsten {
  &:before {
    content: $ikon-skorsten;
  }
}
.ikon-solafskaermning {
  &:before {
    content: $ikon-solafskaermning;
  }
}
.ikon-starting-a-business {
  &:before {
    content: $ikon-starting-a-business;
  }
}
.ikon-su {
  &:before {
    content: $ikon-su;
  }
}
.ikon-tilslutning-til-bredbaand {
  &:before {
    content: $ikon-tilslutning-til-bredbaand;
  }
}
.ikon-transparens {
  &:before {
    content: $ikon-transparens;
  }
}
.ikon-transport {
  &:before {
    content: $ikon-transport;
  }
}
.ikon-udvendigt-malerarbejde {
  &:before {
    content: $ikon-udvendigt-malerarbejde;
  }
}
.ikon-udvidet-indberetning {
  &:before {
    content: $ikon-udvidet-indberetning;
  }
}
.ikon-unge {
  &:before {
    content: $ikon-unge;
  }
}
.ikon-upload {
  &:before {
    content: $ikon-upload;
  }
}
.ikon-varmeanlaeg {
  &:before {
    content: $ikon-varmeanlaeg;
  }
}
.ikon-ventilation {
  &:before {
    content: $ikon-ventilation;
  }
}
.ikon-vindmoeller {
  &:before {
    content: $ikon-vindmoeller;
  }
}
.ikon-vinduespudsning {
  &:before {
    content: $ikon-vinduespudsning;
  }
}
.ikon-virksomhed {
  &:before {
    content: $ikon-virksomhed;
  }
}
.ikon-yderdoere {
  &:before {
    content: $ikon-yderdoere;
  }
}
.ikon-aarsopgoerelsen {
  &:before {
    content: $ikon-aarsopgoerelsen;
  }
}
.ikon-skatten-2016 {
  &:before {
    content: $ikon-skatten-2016;
  }
}
.ikon-menu {
  &:before {
    content: $ikon-menu;
  }
}
.ikon-dagsbevis {
  &:before {
    content: $ikon-dagsbevis;
  }
}
.ikon-eindkomst {
  &:before {
    content: $ikon-eindkomst;
  }
}
.ikon-facebook {
  &:before {
    content: $ikon-facebook;
  }
}
.ikon-forskning {
  &:before {
    content: $ikon-forskning;
  }
}
.ikon-haandvaerkerfradrag {
  &:before {
    content: $ikon-haandvaerkerfradrag;
  }
}
.ikon-instagram {
  &:before {
    content: $ikon-instagram;
  }
}
.ikon-linkedin {
  &:before {
    content: $ikon-linkedin;
  }
}
.ikon-omregistrering {
  &:before {
    content: $ikon-omregistrering;
  }
}
.ikon-pinterest {
  &:before {
    content: $ikon-pinterest;
  }
}
.ikon-skatten-2015 {
  &:before {
    content: $ikon-skatten-2015;
  }
}
.ikon-skatten-2017 {
  &:before {
    content: $ikon-skatten-2017;
  }
}
.ikon-twitter {
  &:before {
    content: $ikon-twitter;
  }
}
.ikon-udland {
  &:before {
    content: $ikon-udland;
  }
}
.ikon-virksomhedsregistrering {
  &:before {
    content: $ikon-virksomhedsregistrering;
  }
}
.ikon-youtube {
  &:before {
    content: $ikon-youtube;
  }
}
.ikon-loensumsafgift {
  &:before {
    content: $ikon-loensumsafgift;
  }
}
.ikon-cykel {
  &:before {
    content: $ikon-cykel;
  }
}
.ikon-dk-flag {
  &:before {
    content: $ikon-dk-flag;
  }
}
.ikon-ekapital {
  &:before {
    content: $ikon-ekapital;
  }
}
.ikon-kuffert {
  &:before {
    content: $ikon-kuffert;
  }
}
.ikon-more {
  &:before {
    content: $ikon-more;
  }
}
.ikon-underholdsbidrag {
  &:before {
    content: $ikon-underholdsbidrag;
  }
}
.ikon-afgifter {
  &:before {
    content: $ikon-afgifter;
  }
}
.ikon-aktier {
  &:before {
    content: $ikon-aktier;
  }
}
.ikon-ansatte-og-loen {
  &:before {
    content: $ikon-ansatte-og-loen;
  }
}
.ikon-arrow-right {
  &:before {
    content: $ikon-arrow-right;
  }
}
.ikon-arrow-left {
  &:before {
    content: $ikon-arrow-left;
  }
}
.ikon-arrow-up {
	&:before {
		content: $ikon-arrow-up;
	}
}
.ikon-arrow-down {
	&:before {
		content: $ikon-arrow-down;
	}
}
.ikon-bil {
  &:before {
    content: $ikon-bil;
  }
}
.ikon-borger {
  &:before {
    content: $ikon-borger;
  }
}
.ikon-ejendom {
  &:before {
    content: $ikon-ejendom;
  }
}
.ikon-erhverv {
  &:before {
    content: $ikon-erhverv;
  }
}
.ikon-foreninger {
  &:before {
    content: $ikon-foreninger;
  }
}
.ikon-fradrag {
  &:before {
    content: $ikon-fradrag;
  }
}
.ikon-gaver {
  &:before {
    content: $ikon-gaver;
  }
}
.ikon-hjaelp-til-tastselv {
  &:before {
    content: $ikon-hjaelp-til-tastselv;
  }
}
.ikon-indkomst-og-skat {
  &:before {
    content: $ikon-indkomst-og-skat;
  }
}
.ikon-ivaerksaetter {
  &:before {
    content: $ikon-ivaerksaetter;
  }
}
.ikon-log-ind {
  &:before {
    content: $ikon-log-ind;
  }
}
.ikon-luk {
  &:before {
    content: $ikon-luk;
  }
}
.ikon-laan {
  &:before {
    content: $ikon-laan;
  }
}
.ikon-moms {
  &:before {
    content: $ikon-moms;
  }
}
.ikon-personalegoder {
  &:before {
    content: $ikon-personalegoder;
  }
}
.ikon-skattekonto {
  &:before {
    content: $ikon-skattekonto;
  }
}
.ikon-soeg {
  &:before {
    content: $ikon-soeg;
  }
}
.ikon-told {
  &:before {
    content: $ikon-told;
  }
}
.ikon-betalings-id {
  &:before {
    content: $ikon-betalings-id;
  }
}
.ikon-log-ud {
  &:before {
    content: $ikon-log-ud;
  }
}
