$loading-height: 43px;
$player-item-size: 36px;

#readspeaker_button1 {
	.rsbtn_toolpanel {
		display: none;
	}

	margin: 0;
	&.rsexpanded {
		height: 79px;
	}
	.rsbtn_tooltoggle {
		display: none !important;
	}
	.rsbtn_play {
		border: none;
		background: none;
		&:focus {
			span {
				color: var(--color-primary);
			}
		}
	}
	.rsbtn_right {
		display: none;
	}

	.rsbtn_left {
		margin: 0;
	}

	.rsbtn_left .rsbtn_text {
		display: flex;

		span {
			padding: 0;
			font-family: var(--font-primary);
			font-weight: var(--font-weight--normal);
			font-size: var(--font-size--md);
		}
		&:before {
			content: none;
		}
		&:after {
			content: none;
		}
	}

	.rsbtn_exp {
		height: $loading-height;
	}

	.readspeaker_icon {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 24px;
		width: 24px;
		min-width: 24px;
		border-radius: var(--corner-circle);
		border: var(--border--sm) solid var(--color-primary);
		margin-right: var(--spacing--sm);
		padding: 0;
	}

	.rsbtn_exp_inner {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		border-top: none;
		height: calc(100% - 1px);
		border-right: none;
		background-color: var(--color-primary);
	}

	.rs-player-loadindicator {
		order: 1;
		grid-column: 1 / -1;
		background: none;
		height: 100%;
		top: 0;
		.rsbtn-loading {
			background: var(--color-primary--60);
		}
	}

	.rsbtn-progress {
		background: var(--color-primary--60) !important;
	}

	.rsbtn_play[aria-expanded="true"] {
		display: none;
	}

	.rsbtn_player_item {
		background-color: var(--color-primary);
		height: $player-item-size;
		width: $player-item-size;
		margin-top: $loading-height;
		border-color: var(--color-primary--60);
		border-width: 1px;
		&:first-child {
			border-left: 1px solid transparent;
		}
	}
	.rsicn::before {
		color: var(--color-white);
	}
	.rsbtn_dropdown {
		background: var(--color-primary);
		padding: 5px 0;
		top: $loading-height;
		width: $player-item-size;

		border-top: none;
		.rs-slider.vertical {
			position: absolute;
			left: 50%;
			transform: rotate(270deg) translateX(-67px);
		}
	}
}

#rs-controlpanel {
	background-color: var(--color-primary) !important;

	.rs-controlpanel-btn,
	.rsicn-play,
	.rsicn-pause {
		color: var(--color-white);
	}

	.rsicn-logo {
		&:after,
		&:before {
			color: var(--color-white) !important;
		}
	}
	.rs-controlpanel-scroll-label,
	.rs-controlpanel-scroll-state {
		color: var(--color-white) !important;
	}
}

#readspeaker_tooltip {
	top: 150px !important;
	white-space: normal !important;
}
