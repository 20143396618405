/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */

:root {
	--spacing--base: 1rem; // 16px

	--spacing--2xs: calc(var(--spacing--base) / 8); // 2px
	--spacing--xs: calc(var(--spacing--base) / 4); // 4px
	--spacing--sm: calc(var(--spacing--base) / 2); // 8px
	--spacing--md: calc(var(--spacing--base) * 1); // 16xp
	--spacing--lg: calc(var(--spacing--base) * 1.5); // 24px
	--spacing--xl: calc(var(--spacing--base) * 2); // 32px
	--spacing--2xl: calc(var(--spacing--base) * 3); // 48px
	--spacing--3xl: calc(var(--spacing--base) * 5); // 80px
}
