// Variables

$skat-blue-primary: var(--color-primary);
$skat-blue-secondary: var(--color-primary--60);
$focus-outline: 3px solid $skat-blue-secondary;
$datepicker-border: 1px solid var(--color-primary--40);
$datepicker-inner-border: 1px solid var(--color-primary--20);
$datepicker-background-color: var(--color-white);
$datepicker-table-border: 1px solid var(--color-primary--20);
$datepicker-body-background-color: var(--color-light);
$datepicker-header-background-color: var(--color-light);
$datepicker-header-text-color: var(--color-black);
$datepicker-previous-next-month-text-color: var(--color-primary--40);
$datepicker-previous-next-month-background-color: var(--color-light);
$font-weight-semibold: var(--font-weight--semibold);
$datepicker-selected-date-text-color: var(--color-white);
$datepicker-selected-month-text-color: var(--color-black);
$datepicker-selected-year-text-color: var(--color-black);

// Date Picker

.button-unstyled{
  background-color:transparent;
  border:0;
  border-radius:0;
  font-weight: var(--font-weight--normal);
  margin:0;
  padding:0;
  -webkit-font-smoothing:auto;
  color:inherit;
  min-height:auto;
}
.button-unstyled:focus{ // .button-unstyled:hover, should it have a hover style?? meybe an offwhite color or a small border
  outline: $focus-outline;
  position: relative;
  z-index: 100;
}

.skat-date-picker__wrapper {
  display: block;
  position: relative;

  &:focus {
    outline: 0;
  }
}

// Date Picker -- initialized

.skat-date-picker--active, .date-picker--initialized {
  .date-picker__calendar {
    z-index: 400;
  }
}

.skat-date-picker__external-input{
  max-width: none;
  width: calc(100% - 16px - 16px);
}
.skat-date-picker__button { //TODO focus is not right, its missing a sorta fade transition like the inputfields have. Add to every focus when solved
  @extend .button-unstyled;
  align-self: stretch;
  background-color: $skat-blue-primary;
  width: 3.125rem;
  height: 3.125rem;
  align-items: center;
  justify-content: center;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: $skat-blue-primary;
}
.skat-date-picker__button:before {
  color: var(--color-white);
  font-family: dap-ikon-font;
  font-size: 1.5rem;
  content: $ikon-kalender;
}

.skat-date-picker--initialized {
  .skat-date-picker__wrapper {
    display: flex;
  }
}

// Date Picker - Calendar View

.skat-date-picker__calendar {
  border: $datepicker-border;
  background-color: $datepicker-background-color;
  box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
  left: 0;
  position: absolute;
  width: 100%;
  min-width: 232px; // TODO This is the minimum size before the calendar starts to bug out visually, unless we change font type/size... wait, see font-size
  max-width: 282px; // TODO there should prolly be a max width, but idk what size
  // @include media-breakpoint-up(md) {
  //   width: 232px;
  // }
  z-index: 100;
  margin-top: 4px;
  font-size: 0.80rem;
  // TODO font-size is needed to display the calender on smaller screens, but we could also do something with font-size only gets smaller below a certain screensize
}

// Date Picker - Table

.skat-date-picker__calendar__table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  width: 100%;

  thead{
    border-bottom: $datepicker-table-border;
  }

  th {
    font-weight: normal;
  }

  td {
    padding: 0;
  }
}

.skat-date-picker__calendar__month-picker .skat-date-picker__calendar__table, .skat-date-picker__calendar__year-picker .skat-date-picker__calendar__table{
  thead, tr{
    border: 0;
  }
}

// Date Picker - Grid

.skat-date-picker__calendar__row {
  display: flex;
  text-align: center;
  width: 100%;
  background-color: $datepicker-body-background-color;
  border-bottom: $datepicker-table-border;
}

.skat-date-picker__calendar__cell {
  background-color: $datepicker-header-background-color;
  color: $datepicker-header-text-color;
  flex: 1;
}

.skat-date-picker__calendar__cell--center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Date Picker - Date Selection View

.skat-date-picker__calendar__previous-year,
.skat-date-picker__calendar__previous-month,
.skat-date-picker__calendar__next-year,
.skat-date-picker__calendar__next-month {
  @extend .button-unstyled;
  background-position: center;
  height: 30px;
}

.skat-date-picker__calendar__previous-year:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-left;
}

.skat-date-picker__calendar__previous-month:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-left;
}

.skat-date-picker__calendar__next-year:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-right;
}

.skat-date-picker__calendar__next-month:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-right;
}

.skat-date-picker__calendar__day-of-week {
  padding: 6px 0px;
}

.skat-date-picker__calendar__date {
  @extend .button-unstyled;
  text-align: right;
  width: 100%;
  padding: 10px 12px 10px 0px;
  position: relative;

  &:after {
    position: absolute;
    top: -1px;
    bottom: 0px;
    left: -1px;
    right: 0px;
    border: $datepicker-inner-border;
    content: "";
    display: block;
    pointer-events: none;
  }

  &[disabled]{
    opacity: 0.3;
    cursor: not-allowed;
  }

  // don't think this is needed
//   &--focused{
//     position: relative;
//     z-index: 100;
    
//     &:after {
//       border: 1px solid gray; // $outer-border;
//     }
//   }

  &:focus {
    outline: none;
    &:before {
      outline: $focus-outline;
      // outline-offset: 1px;
      position: absolute;
      top: -1px;
      bottom: 0px;
      left: -1px;
      right: 0px;
      content: "";
      display: block;
      pointer-events:none;
      box-shadow: black;
    }
  }


  &--next-month:not([disabled]),
  &--previous-month:not([disabled]) {
    color: $datepicker-previous-next-month-text-color;
    text-indent: -99999px;
    background-color: $datepicker-previous-next-month-background-color;
  }

  &--selected,
  &--range-date {
    background-color: $skat-blue-primary;
    color: $datepicker-selected-date-text-color;
    font-weight: var(--font-weight--semibold);

    &:not([disabled]) {
      background-color: $skat-blue-primary;
      color: $datepicker-selected-date-text-color;
    }
  }


  // don't think this is needed
  // &--range-date-start {
  //   border-top-left-radius: 10%;
  //   border-bottom-left-radius: 10%;
  // }

  // &--range-date-end {
  //   border-top-right-radius: 10%;
  //   border-bottom-right-radius: 10%;
  // }

  &--within-range {
    background-color: $skat-blue-secondary;
    color: $datepicker-selected-date-text-color;

    &:not([disabled]) {
      background-color: $skat-blue-secondary;

      &:hover {
        background-color: $skat-blue-secondary;
      }

      &:focus {
        background-color: $skat-blue-secondary;
      }

      &:active {
        background-color: $skat-blue-secondary;
      }
    }
  }

}

.skat-date-picker__calendar__month-label {
  flex: 4;
  text-align: center;
}

.skat-date-picker__calendar__year-selection,
.skat-date-picker__calendar__month-selection {
  @extend .button-unstyled;
  display: inline-block;
  height: 100%;
  padding: 8px 3px;
  width: auto;
  font-weight: $font-weight-semibold;
}

// Date Picker - Month Selection View

.skat-date-picker__calendar__month-picker {
  padding: 20px 5px;
}

.skat-date-picker__calendar__month {
  @extend .button-unstyled;
  padding: 10px 0;
  width: 100%;
  display: block;
  text-align: center;

  // don't think this is needed
//   &--focused {
//     outline: 4px solid #72728a; // $focus-outline;
//     outline-offset: 2px;
//     position: relative;
//     z-index: 100;
//   }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--selected {
    color: $datepicker-selected-month-text-color;
    font-weight: var(--font-weight--semibold);

    &:not([disabled]) {

      &:hover {
        color: $datepicker-selected-month-text-color;
      }

      &:focus {
        color: $datepicker-selected-month-text-color;
      }
    }
  }
}

// Date Picker - Year Selection View

.skat-date-picker__calendar__year-picker {
  padding: 20px 5px;
}

.skat-date-picker__calendar__previous-year-chunk,
.skat-date-picker__calendar__next-year-chunk {
  @extend .button-unstyled;
  background-position: center;
  margin: auto;
  padding: 0px 8px;
}

.skat-date-picker__calendar__previous-year-chunk:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-left;
}

.skat-date-picker__calendar__next-year-chunk:not([disabled]):before {
  color: $skat-blue-primary;
  font-family: dap-ikon-font;
  font-size: var(--font-size--md);
  content: $ikon-arrow-right;
}

.skat-date-picker__calendar__year {
  @extend .button-unstyled;
  padding: 10px 0;
  width: 100%;
  display: block;
  text-align: center;

  // don't think this is needed
//   &--focused {
//     outline: 4px solid #72728a; // $focus-outline;
//     outline-offset: 1px;
//     position: relative;
//     z-index: 100;
//   }
  

  &[disabled]{
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--selected {
    color: $datepicker-selected-year-text-color;
    font-weight: var(--font-weight--semibold);

    &:not([disabled]) {

      &:hover {
        color: $datepicker-selected-year-text-color;
      }

      &:focus {
        color: $datepicker-selected-year-text-color;
      }
    }
  }
}