$form-font-size: $font-size-base * 1.125;
$form-control-height: 3.125rem;

.form-control[readonly],
.form-control[readonly] + .input-group-append > .input-group-text {
  border-style: dotted;
}

.invalid-feedback {
  font-size: 100%;
}

.input-group-text {
  &.align-units {
    min-width: 5rem;
  }
}

.input-group-prepend .btn {
  min-width: 3.125rem;
}

.input-group-sum {
  border-bottom: double 4px black;
  .form-control,
  .input-group-text {
    border-color: transparent;
  }
}

.input-group-borderless {
  .form-control,
  .input-group-text {
    border-color: transparent;
  }
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn .ikon {
  display: flex;
  justify-content: center;
}

/* Firefox */
input.no-arrows[type='number'] {
  -moz-appearance: textfield;
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    box-shadow: $input-focus-box-shadow;
  }

  .was-validated
    .custom-control-input:valid:focus
    ~ .custom-control-label::before,
  .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }
}
