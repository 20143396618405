.theme-webguide {
	--color-theme-primary: #1c69b9;
	--color-theme-primary--60: #77a5d5;
	--color-theme-primary--30: #bbd2ea;

	--color-theme-contrast: var(--color-white);

	--color-theme-button: var(--color-white);
	--color-theme-button-contrast: var(--color-primary);
}
