.theme-toldst {
	--color-theme-primary: #ffbb16;
	--color-theme-primary--60: #ffd673;
	--color-theme-primary--30: #ffebb9;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);
}
