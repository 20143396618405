// Delivery from Cludo - not optimzed

#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a:focus,
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li:focus {
	box-shadow: 0 0 0 2px #fff, 0 0 0 6px #72728a;
}
#cludo-search-results h2,
#cludo-search-results li,
#cludo-search-results ul,
#cludo-search-results.search_autocomplete,
ul.search_autocomplete h2,
ul.search_autocomplete li,
ul.search_autocomplete ul,
ul.search_autocomplete.search_autocomplete {
	margin: 0;
	padding: 0;
}
#cludo-search-results ul,
#cludo-search-results.search_autocomplete,
ul.search_autocomplete ul,
ul.search_autocomplete.search_autocomplete {
	list-style: none;
}
#cludo-search-results .cludo-facet__header {
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 10px;
}
#cludo-search-results .cludo-facet__header-mobile {
	display: none;
	position: relative;
	font-size: 16px;
	margin: 5px 0;
	padding: 0.75rem 2rem;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet__value-list {
		display: none;
	}
	#cludo-search-results .cludo-facet__header-mobile {
		display: block;
		cursor: pointer;
		background-color: #14143c;
		color: #fff;
		border: 1px solid #14143c;
		margin-bottom: 0;
	}
	#cludo-search-results .cludo-facet__header-mobile:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		margin-left: 0.4em;
		vertical-align: 0.4em;
		border-top: 0.4em solid;
		border-right: 0.4em solid transparent;
		border-bottom: 0;
		border-left: 0.4em solid transparent;
		position: absolute;
		top: 22px;
		right: 32px;
	}
	#cludo-search-results .cludo-facet__header-mobile.open:after {
		transform: rotate(0.5turn);
	}
}
#cludo-search-results .cludo-facet.cludo-facet-Category {
	margin-top: 5px;
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list {
	display: flex;
	flex-wrap: wrap;
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li {
	margin-top: 2px;
	margin-right: 2px;
	background-color: #14143c;
	border: 1px solid #14143c;
	transition: background-color 0.3s;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list {
		display: none;
		border: 1px solid #14143c;
	}
	#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li {
		margin: 0;
		border: 0;
		background-color: #fff;
	}
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li.active {
	background-color: #fff;
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a {
	display: flex;
	align-items: center;
	color: #fff;
	padding: 0.75rem 2rem;
	text-align: center;
	text-decoration: none;
	width: 100%;
	height: 100%;
	transition: color 0.3s;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a {
		position: relative;
		margin: 0;
		padding: 0.3rem 2rem;
		color: #14143c;
	}
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a.active {
	color: #14143c;
}
#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a.active::before {
	content: "\e97d";
	font-family: dap-ikon-font !important;
	padding-right: 15px;
	font-size: 80%;
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory {
	display: none;
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 25px;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet.cludo-facet-Category .cludo-facet__value-list li a.active::before {
		position: absolute;
		left: 0.75rem;
		font-size: 65%;
	}
	#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list {
		display: none;
		border: 1px solid #14143c;
		margin-top: 0;
	}
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li {
	margin-top: 10px;
	margin-right: 25px;
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a {
	display: flex;
	align-items: center;
	transition: background-color 0.3s;
	text-decoration: none;
	position: relative;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li {
		margin: 0;
	}
	#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a {
		margin: 0;
		padding: 0.5rem 2rem;
		color: #14143c;
	}
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a:before {
	content: "";
	width: 23px;
	min-width: 23px;
	height: 23px;
	border: 8px;
	border: 1px solid #14143c;
	border-radius: 4px;
	margin-right: 5px;
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a.active:before {
	background-color: #14143c;
}
#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a.active::after {
	content: "\e97d";
	position: absolute;
	font-family: dap-ikon-font !important;
	padding-right: 15px;
	font-size: 50%;
	left: 7px;
	color: #fff;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-facet.cludo-facet-Subcategory .cludo-facet__value-list li a.active::after {
		left: 38px;
	}
}
#cludo-search-results .cludo-search-results-list {
	margin-top: 50px;
	width: 100%;
}
#cludo-search-results .cludo-search-results-item__title,
#cludo-search-results .cludo-search-results-list__banner h2,
#cludo-search-results .cludo-search-results-list__banner h3,
#cludo-search-results .cludo-search-results-list__banner h4 {
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;
	word-break: break-word;
}
#cludo-search-results .cludo-search-results-list__banner {
	max-width: 47rem;
	padding: 1.875rem;
	border: 2px solid #14143c;
}
#cludo-search-results .cludo-search-results-list__banner ul {
	list-style: outside;
}
#cludo-search-results .cludo-search-results-list__banner a {
	text-decoration: none;
	position: relative;
}
#cludo-search-results .cludo-search-results-list__banner a::after {
	content: "";
	background-image: url(https://customer.cludo.com/assets/2073/13064/chevron.svg);
	width: 20px;
	height: 20px;
	position: absolute;
	right: -35px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
}
#cludo-search-results .cludo-search-results-list__banner a:hover::after {
	right: -25px;
}
#cludo-search-results .cludo-search-results-item {
	border: 1px solid transparent;
	border-bottom: 1px solid #e5e5e5;
	padding: 1.875rem 0;
	max-width: 47rem;
}
#cludo-search-results .cludo-search-results-item:hover .cludo-search-results-item__description-icon {
	padding-left: 10px;
}
#cludo-search-results .cludo-search-results-item__breadcrumbs {
	display: flex;
	flex-wrap: wrap;
}
#cludo-search-results .cludo-search-results-item__breadcrumbs div {
	font-size: 75%;
	font-weight: 400;
	position: relative;
}
#cludo-search-results .cludo-search-results-item__breadcrumbs__icon {
	width: 25px;
	position: relative;
	padding: 0 10px;
}
#cludo-search-results .cludo-search-results-item__breadcrumbs__icon::after {
	content: "";
	background-image: url(https://customer.cludo.com/assets/2073/13064/chevron.svg);
	background-size: cover;
	width: 16px;
	height: 18px;
	position: absolute;
	left: 4px;
	top: 0;
}
#cludo-search-results .cludo-search-results-item__breadcrumbs__icon:last-child {
	display: none;
}
#cludo-search-results .cludo-search-results-item__title-anchor {
	text-decoration: none;
}
#cludo-search-results .cludo-search-results-item__description {
	word-break: break-word;
}
#cludo-search-results .cludo-search-results-item__description-icon {
	transition: all 0.3s ease-in-out;
}
#cludo-search-results .cludo-search-results-bottom {
	margin: 2.7rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-search-results-bottom {
		flex-direction: column;
		align-items: flex-start;
	}
}
#cludo-search-results .cludo-search-results-bottom__left {
	display: flex;
	align-items: center;
}
#cludo-search-results .cludo-search-results-bottom__totalDocuments {
	padding-right: 15px;
	border-right: 1px solid #e5e5e5;
	margin-right: 15px;
	height: 35px;
	display: flex;
	align-items: center;
}
#cludo-search-results .cludo-search-results-bottom__resultPicker {
	height: 35px;
	display: flex;
	align-items: center;
}
#cludo-search-results .cludo-search-results-bottom select {
	border-radius: 0;
	text-align: center;
	height: 35px;
	margin: 0 5px;
}
@media (max-width: 768px) {
	#cludo-search-results .cludo-page-navigation {
		width: 100%;
		margin-top: 20px;
	}
}
#cludo-search-results .cludo-page-navigation__list {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
#cludo-search-results .cludo-page-navigation__list__arrows {
	display: flex;
	margin-left: 10px;
}
#cludo-search-results .cludo-page-navigation__list__arrows li a {
	width: 32px;
	height: 32px;
	padding: 0.5rem;
	color: #fff;
	background-color: #14143c;
	border-color: #14143c;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	font-family: dap-ikon-font !important;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	display: flex;
	justify-content: center;
	margin-right: 0.3rem;
	text-decoration: none;
}
#cludo-search-results .cludo-page-navigation__list__arrows li a:focus {
	box-shadow: 0 0 0 0.3rem #72728a;
	background-color: #434363;
}
#cludo-search-results .cludo-page-navigation__list__arrows li a:hover {
	outline: #fff solid 1px;
	box-shadow: 0 0 0 0.3rem #72728a;
	background-color: #434363;
}
#cludo-search-results .cludo-page-navigation__list__arrows li.previous a:before {
	content: "\e97b";
}
#cludo-search-results .cludo-page-navigation__list__arrows li.next a {
	margin-right: 0;
}
#cludo-search-results .cludo-page-navigation__list__arrows li.next a:before {
	content: "\e903";
}
#cludo-search-results .search-result-count {
	font-size: 20px;
	line-height: 24px;
	padding-top: 40px;
}
#cludo-search-results .cludo-search-no-results {
	width: 100%;
}
#cludo-search-results .cludo-search-no-results a {
	color: #14143c;
	font-weight: 400;
	text-decoration: underline;
	cursor: pointer;
}
#cludo-search-results .cludo-search-results__layout {
	margin: 10px 0 0;
}
#cludo-search-results .cludo-search-results__facets {
	margin-top: 20px;
}
#cludo-search-results .cludo-search-results__results {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
#cludo-search-content-form {
	position: relative;
}
#cludo-search-content-form .input-group {
	margin-bottom: 50px;
}
#cludo-search-content-form button,
#cludo-search-content-form2 button {
	height: 3.125rem;
	background-color: #14143c;
	color: #fff;
	position: relative;
}
#cludo-search-content-form button:after,
#cludo-search-content-form2 button:after {
	content: "";
	background: url("../../icons/search.svg") no-repeat;
	filter: invert(1);
	position: absolute;
	width: 25px;
	height: 25px;
	background-size: 100%;
	top: 12.5px;
	left: 24.5px;
}
#cludo-search-content-form2 {
	width: 30%;
	position: relative;
	padding: 0;
}
@media (max-width: 768px) {
	#cludo-search-content-form2 {
		width: 100%;
	}
}
.search_autocomplete {
	position: absolute;
	top: 100%;
	width: 100%;
	background-color: #f9f9f9;
	border: 1px solid #14143c;
	border-top: none;
	z-index: 99999;
}
.search_autocomplete li {
	padding: 7px !important;
	cursor: pointer;
}
.search_autocomplete li.active,
.search_autocomplete li:hover {
	background: #efefef;
}
#cludo-search-content-form .search_autocomplete {
	top: 50px;
}
.cludo-input-form {
	display: flex;
	flex-direction: row;
}
.cludo-input-form__input {
	flex: 1;
}
.cludo-input-form__search-button {
	flex: 0;
}
#cludo-loading {
	padding: 35px;
}
#cludo-loading .spinner {
	height: 35px;
	width: 35px;
}
#loader {
	text-align: center;
}
.cludo-hidden {
	display: none !important;
}
.cludo-opacity0 {
	opacity: 0;
}
