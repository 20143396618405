.table-status-transition {
  transition: height 300ms ease-in-out;
}

.table-status-transition:not(.show) {
  display: none;
}

.table-status {
  &.enter-active,
  &.exit-active {
    overflow: hidden;
  }
}
