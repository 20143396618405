/* ------------------------------------ *\
		elements.strong
\* ------------------------------------ */

/**
 * Elements – unclassed HTML elements,
 * redefine browsers' default styling
*/

strong,
b {
	font-weight: var(--font-weight--bold);
}
