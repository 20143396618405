.theme-skat {
	--color-theme-primary: #2e99d9;
	--color-theme-primary--60: #82c2e8;
	--color-theme-primary--30: #c0e0f4;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);
}
