.form-control-sm.paginator {
	width: 3.5em;
	margin-left: var(--spacing--sm);
	margin-right: var(--spacing--sm);
}

.custom-select.paginator {
	height: calc(1.5em + 0.5rem + 2px);
	padding-left: var(--spacing--xs);
	padding-right: var(--spacing--md);
	padding-top: var(--spacing--2xs);
	padding-bottom: var(--spacing--2xs);
	background: #fff
		url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
		right 0.5rem center/8px 10px no-repeat;
}

.pagination-container {
	display: flex;
}
.cell-one {
	border-right: 1px solid #e5e5e5;
	padding-right: var(--spacing--md);
}
.cell-two {
	margin-left: var(--spacing--md);
}
.cell-three {
	text-align: right;
	margin-left: auto;
}

@media screen and (max-width: 728px) {
	.pagination-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.cell-one {
		grid-column: span 1;
		margin-left: auto;
	}
	.cell-two {
		grid-column: span 1;
	}
	.cell-three {
		grid-column: span 2;
		margin-top: var(--spacing--sm);
		margin-right: auto;
	}
}

@media screen and (max-width: 480px) {
	.pagination-container {
		grid-template-columns: 1fr;
	}
	.cell-one {
		margin-right: auto;
		border-right: none;
		padding-right: 0;
	}
	.cell-two {
		margin-top: var(--spacing--sm);
		margin-left: auto;
		margin-right: auto;
	}
	.cell-three {
		grid-column: span 1;
	}
}
