.action-btn {
  $width: 4rem;
  $spacing-y: 1.2rem;
  $spacing-x: 1.5rem;
  padding: $spacing-y $spacing-x $spacing-y $spacing-x + $width;
  display: inline-block;
  flex-direction: row-reverse;
  text-decoration: none;
  transition: background-color 0.3s;
  border: none;
  position: relative;

  &:focus {
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $blaa;
    outline: none;

    &::before {
      transform: translateX(0.4rem);
    }
  }
  &::before,
  &::after {
    position: absolute;
    top: 0;
    height: 100%;
  }
  &::before {
    font-family: $icomoon-font-family;
    left: 0;
    width: $width;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
  }

  &::after {
    content: '';
    left: $width;
    width: 1px;
    opacity: 0.3;
    border-right: solid 1px;
  }
  &-logon {
    background-color: $skatdk;

    &:hover,
    &:focus {
      background-color: $ufst-60;
    }

    &:before {
      content: $ikon-log-ind;
    }

    &:after {
      border-color: $blaa;
    }
  }

  &-arrow {
    color: white;
    background-color: $blaa;

    &:hover,
    &:focus {
      color: white;
      background-color: $blaa-80;
    }

    &:before {
      content: $ikon-arrow-right;
      font-size: 0.75rem;
    }

    &:after {
      border-color: white;
    }
  }
}
