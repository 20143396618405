.theme-vurdst {
	--color-theme-primary: #ff620c;
	--color-theme-primary--60: #ffa16d;
	--color-theme-primary--30: #ffd0b6;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);
}
