/* ------------------------------------ *\
		settings.behavior
\* ------------------------------------ */

:root {
	// Transistion time
	--trans-time--shortest: 0.1s;
	--trans-time--shorter: 0.2s;
	--trans-time--standard: 0.3s;
	--trans-time--long: 0.4s;
	--trans-time--longer: 0.6s;

	// Easing
	--ease--dynamic: cubic-bezier(0.86, 0, 0.07, 1);
	--ease--elastic: cubic-bezier(0.77, -0.19, 0.33, 1.12);
	--ease--elastic-wild: cubic-bezier(0.55, 1.55, 0.39, 1.01);
	--ease--elastic-subtle: cubic-bezier(0.42, 0, 0, 2.35);
	--ease--tender: cubic-bezier(0.73, 0.09, 0.21, 0.96);

	// https://easings.net/en#easeInOutExpo
	--ease--in-out-expo: cubic-bezier(1, 0, 0, 1);
	--ease--in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
}
