.theme-adst {
	--color-theme-primary: #aac800;
	--color-theme-primary--60: #ccde66;
	--color-theme-primary--30: #e5eeb2;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);
}
