.has-spinner {
  transition: color 0.3s ease-in-out;
  position: relative;

  .spinner {
    opacity: 0;
    position: absolute;
    left: calc(50% - 15px);
    top: calc(50% - 15px);
  }

  [aria-busy='true'] &,
  &[aria-busy='true'] {
    color: transparent !important;

    .spinner {
      opacity: 1;
      display: block;
    }
  }
}

#ds-full-page-spinner {
  filter: none;
}

.spinner {
  height: 30px;
  width: 30px;
  animation-name: rotating;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: opacity 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  #ds-full-page-spinner & {
    position: static;
  }

  &.spinner--light {
    background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%0D%0A%09xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2238px%22%20height%3D%2238px%22%20viewBox%3D%220%200%2038%2038%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20d%3D%22M19%2C3.1c8.8%2C0%2C15.9%2C7.1%2C15.9%2C15.9S27.8%2C34.9%2C19%2C34.9c-4.3%2C0-8.3-1.7-11.1-4.5l-2.1%2C2.2%0D%0A%09C9.2%2C35.9%2C13.8%2C38%2C19%2C38c10.5%2C0%2C19-8.5%2C19-19S29.5%2C0%2C19%2C0l0%2C0C13.8%2C0%2C9.2%2C2.1%2C5.7%2C5.4l2.2%2C2.2C10.8%2C4.8%2C14.7%2C3.1%2C19%2C3.1%22%2F%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M7.9%2C30.4c-3-2.9-4.8-6.9-4.8-11.4c0-4.5%2C1.8-8.5%2C4.8-11.4L5.7%2C5.4C2.2%2C8.9%2C0%2C13.7%2C0%2C19%0D%0A%09c0%2C5.3%2C2.2%2C10.2%2C5.7%2C13.6L7.9%2C30.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
  }

  &.spinner--dark {
    background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2250.6px%22%20height%3D%2250.6px%22%20viewBox%3D%22-0.5%20-0.5%2050.6%2050.6%22%20enable-background%3D%22new%20-0.5%20-0.5%2050.6%2050.6%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M24.8-0.5c-14%2C0-25.3%2C11.3-25.3%2C25.3s11.3%2C25.3%2C25.3%2C25.3s25.3-11.3%2C25.3-25.3S38.8-0.5%2C24.8-0.5z%0D%0A%09%20M45.1%2C24.8c0%2C11.2-9.1%2C20.3-20.3%2C20.3C13.6%2C45.1%2C4.5%2C36%2C4.5%2C24.8S13.6%2C4.5%2C24.8%2C4.5C36%2C4.5%2C45.1%2C13.6%2C45.1%2C24.8z%22%2F%3E%0D%0A%09%3Cg%3E%0D%0A%09%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%2314143C%22%20enable-background%3D%22new%22%20d%3D%22M24.8%2C4c11.5%2C0%2C20.8%2C9.3%2C20.8%2C20.8s-9.3%2C20.8-20.8%2C20.8%0D%0A%09%09c-5.7%2C0-10.8-2.3-14.5-5.9l-2.8%2C2.9c4.5%2C4.4%2C10.6%2C7%2C17.3%2C7c13.7%2C0%2C24.8-11.1%2C24.8-24.8S38.5%2C0%2C24.8%2C0l0%2C0C18.1%2C0%2C12%2C2.7%2C7.5%2C7.1%0D%0A%09%09l2.8%2C2.9C14%2C6.3%2C19.2%2C4%2C24.8%2C4%22%2F%3E%0D%0A%09%09%3Cpath%20fill%3D%22%2314143C%22%20d%3D%22M10.3%2C39.7C6.4%2C35.9%2C4%2C30.6%2C4%2C24.8s2.4-11.1%2C6.3-14.9L7.5%2C7.1C2.9%2C11.6%2C0%2C17.9%2C0%2C24.8%0D%0A%09%09c0%2C7%2C2.9%2C13.3%2C7.5%2C17.8L10.3%2C39.7z%22%2F%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

body.modal-open[aria-busy='true'] {
  .site-wrapper > *,
  &.site-wrapper > * {
    filter: blur(2px);
  }

  .modal-backdrop {
    background-color: white;
    opacity: 0.8;
  }

  .modal-content {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

fieldset[disabled] {
  pointer-events: none;
}
