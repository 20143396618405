.u-bg-color--white {
	background-color: var(--color-white);
	color: var(--color-primary);
	a:hover {
		color: var(--color-primary);
	}
}
.u-bg-color--primary100 {
	background-color: var(--color-primary);
	color: var(--color-white);
	a:hover {
		color: var(--color-white);
	}
}
.u-bg-color--primary40 {
	background-color: var(--color-primary--40);
	color: var(--color-primary);
	a:hover {
		color: var(--color-primary);
	}
}
.u-bg-color--primary20 {
	background-color: var(--color-primary--20);
	color: var(--color-primary);
	a:hover {
		color: var(--color-primary);
	}
}
.u-bg-color--theme {
	background-color: var(--color-theme-primary);
	color: var(--color-theme-contrast);
	a:hover {
		color: var(--color-theme-contrast);
	}
}
