.theme-sktst {
	--color-theme-primary: #aecbda;
	--color-theme-primary--60: #cee0e9;
	--color-theme-primary--30: #e7eff4;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);
}
