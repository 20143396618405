.alert.filter {
  display: inline-flex;
  align-items: center;
  border: solid 1px $blaa;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  position: relative;
  margin-bottom: 1rem;
  margin-right: 1rem;

  .close {
    height: auto;
    width: auto;
    position: relative;
    opacity: 1;
    font-size: 1.7rem;
    color: #14143c;
    padding: 0 0.25rem;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }
}