.theme-skm {
	--color-theme-primary: #d1c5c3;

	--color-theme-contrast: var(--color-primary);

	--color-theme-button: var(--color-primary);
	--color-theme-button-contrast: var(--color-white);

	--font-heading: "Republic", sans-serif;
	--font-primary: Arial, sans-serif;
}
