.theme-gaeldst {
	--color-theme-primary: #df0515;
	--color-theme-primary--60: #ec6973;
	--color-theme-primary--30: #f5b4b9;

	--color-theme-contrast: var(--color-white);

	--color-theme-button: var(--color-white);
	--color-theme-button-contrast: var(--color-primary);

	--width-sm: calc(var(--width--base) * 0.9);
}
